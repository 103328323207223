var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"px-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-right mr-3 mb-3"},[_c('span',{staticClass:"mr-3"},[_vm._v("Filter by:")]),_vm._v(" "),(_vm.photoFilter !== undefined)?_c('v-btn',{staticClass:"mr-3",attrs:{"icon":"","outlined":"","small":""},on:{"click":_vm.resetFilters}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fal fa-times")])],1):_vm._e(),_vm._v(" "),_c('v-btn-toggle',{on:{"change":_vm.onFilterChange},model:{value:(_vm.photoFilter),callback:function ($$v) {_vm.photoFilter=$$v},expression:"photoFilter"}},[_c('v-btn',{attrs:{"small":""}},[_vm._v("\n            Elite\n          ")]),_vm._v(" "),_c('v-btn',{attrs:{"small":""}},[_vm._v("\n            Inspiration\n          ")]),_vm._v(" "),_c('v-btn',{attrs:{"small":""}},[_vm._v("\n            Prime\n          ")]),_vm._v(" "),_c('v-btn',{attrs:{"small":""}},[_vm._v("\n            Upcoming\n          ")]),_vm._v(" "),_c('v-btn',{attrs:{"small":""}},[_vm._v("\n            Opening\n          ")])],1)],1),_vm._v(" "),_c('vue-justified-layout',{attrs:{"items":_vm.photos},scopedSlots:_vm._u([{key:"inner",fn:function(slotProps){return [(slotProps && slotProps.item)?_c('nuxt-link',{staticClass:"photo-link",attrs:{"to":`/photo/${slotProps.item.id}/${slotProps.item.slug}?from=photographer&pid=${_vm.pid}`}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return _c('v-img',{key:slotProps.item.id,attrs:{"src":_vm.$asa.getImageUrl(slotProps.item, 'thumb'),"lazy-src":_vm.$asa.getImageUrl(slotProps.item, 'thumb', true),"alt":`${slotProps.item.title} by ${slotProps.item.photographerName} - 100ASA`},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0 color",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)]},proxy:true}],null,true)},[_vm._v(" "),(
                    ((_vm.me && _vm.me.isNudeFilterActive) || !_vm.me) &&
                      slotProps.item.isNude
                  )?_c('div',{staticClass:"obscure-photo"},[_c('p',{staticClass:"text-body-2 ma-0"},[_vm._v("NSFW Content")])]):_vm._e(),_vm._v(" "),(
                    slotProps.item.statusName !==
                      _vm.types.photo.status.inCuration &&
                      slotProps.item.statusName !==
                        _vm.types.photo.status.uploaded
                  )?_c('div',{staticClass:"corner-ribbon white--text",class:{
                    'lime darken-2':
                      slotProps.item.statusName ===
                      _vm.types.photo.status.inspiration,
                    success:
                      slotProps.item.statusName === _vm.types.photo.status.prime,
                    info:
                      slotProps.item.statusName ===
                      _vm.types.photo.status.upcoming,
                    purple:
                      slotProps.item.statusName ===
                      _vm.types.photo.status.opening,
                    gold:
                      slotProps.item.statusName === _vm.types.photo.status.elite
                  }},[_vm._v("\n                  "+_vm._s(_vm.$asa.getFriendlyPhotoStatusName(slotProps.item.statusName))+"\n                ")]):_vm._e(),_vm._v(" "),_c('v-fade-transition',{attrs:{"mode":"in-out"}},[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out photo-overlay pa-2",staticStyle:{"height":"50px"}},[_c('div',{staticClass:"d-flex pl-1 credits justify-end align-center w-100-percentage"},[_c('div',[_c('v-btn',{attrs:{"color":"secondary","icon":""},on:{"click":function($event){return _vm.openAddToGalleryDialog($event, slotProps.item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("\n                            fal fa-layer-plus\n                          ")])],1)],1)])]):_vm._e()])],1)}}],null,true)})],1):_vm._e()]}}])}),_vm._v(" "),_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}]}),_vm._v(" "),(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('v-icon',{staticClass:"fa-spin my-3"},[_vm._v(" fal fa-spinner ")])],1):_vm._e(),_vm._v(" "),(!_vm.loading && !_vm.mounting && _vm.photos.length === 0)?_c('div',[_c('div',{staticClass:"text-center pt-2"},[_c('h3',{staticClass:"text-h6 mb-2"},[_vm._v("No photos")]),_vm._v(" "),_c('p',{staticClass:"text-subtitle-1"},[_vm._v("\n            There are not photos to show here\n          ")])])]):_vm._e()],1)],1),_vm._v(" "),_c('Galleries-dialog')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }