<template>
  <div>
    <div
      :style="
        photographer.bestUserPhoto && mounted
          ? `background-image: url(${$asa.getImageUrl(photographer.bestUserPhoto, 'full')})`
          : ''
      "
      class="tertiary photographer-hero d-flex"
    >
      <div class="overlay d-flex flex-column justify-end pb-6">
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="d-md-flex align-center text-center text-md-left">
                <div class="mr-md-6 mb-6 mb-md-0">
                  <v-avatar size="120">
                    <img
                      :src="photographer.avatarUrl"
                      :alt="`${fullName} avatar.`"
                    />
                  </v-avatar>
                </div>
                <div
                  v-if="photographer.hasAnActiveSubscription && photographer.subscriptionType !== types.subscription.type.basic"
                  class="d-block d-md-none"
                >
                  <div
                    :class="
                      $vuetify.theme.dark
                        ? 'lightinfo black--text'
                        : 'secondary primary--text'
                    "
                    class="
                      d-inline-block
                      pro-badge
                      text-body-1
                      font-weight-bold
                      mb-4
                    "
                  >
                    <small>
                      {{
                        photographer.subscriptionType ===
                        types.subscription.type.pro
                          ? 'PRO'
                          : 'PRO +'
                      }}
                    </small>
                  </div>
                </div>
                <div class="secondary--text">
                  <h1 class="text-h4 font-weight-bold mb-4 mb-md-2">
                    {{ fullName }}
                  </h1>
                  <div class="d-md-flex align-center mb-2">
                    <h2 class="text-subtitle-1 text-uppercase font-weight-bold">
                      <span v-if="photographer.country" class="mr-2 va-top">
                        <img
                          :src="`https://www.flagcdn.com/h20/${photographer.country.toLowerCase()}.png`"
                          :srcset="`https://flagcdn.com/h20/${photographer.country.toLowerCase()}.png 2x`"
                        />
                      </span>
                      {{ photographer.role
                      }}{{ photographer.isCritic ? ' &amp; CRITIC' : '' }}
                      <span class="mx-2">-</span>
                      <span
                        v-if="
                          photographer.role === types.user.role.curator ||
                          photographer.role === types.user.role.ambassador ||
                          photographer.isCritic
                        "
                      >
                        <img
                          src="/images/100asa-round-icon-white.png"
                          class="asa-icon"
                          alt="100ASA icon."
                          width="20"
                          height="20"
                        />
                      </span>
                      <span v-else>
                        <v-icon small class="mx-1 secondary--text">
                          fal fa-trophy
                        </v-icon>
                        {{ photographer.reputation ? photographer.reputation.toLocaleString() : 0 }}
                      </span>
                    </h2>
                    <v-divider
                      vertical
                      class="secondary mx-6 d-none d-md-block"
                    />
                    <h3 v-if="photographer.joiningDate" class="text-body-2">
                      Member since {{ photographer.joiningDate | printdate }}
                    </h3>
                  </div>
                  <div
                    :class="{ 'mb-6': $vuetify.breakpoint.smAndDown }"
                    class="d-flex align-center justify-center justify-md-start"
                  >
                    <div
                      v-if="photographer.hasAnActiveSubscription && photographer.subscriptionType !== types.subscription.type.basic"
                      class="d-none d-md-block"
                    >
                      <div
                        :class="
                          $vuetify.theme.dark
                            ? 'lightinfo black--text'
                            : 'secondary primary--text'
                        "
                        class="
                          d-inline-block
                          pro-badge
                          text-body-1
                          font-weight-bold
                        "
                      >
                        <small>
                          {{
                            photographer.subscriptionType ===
                            types.subscription.type.pro
                              ? 'PRO'
                              : 'PRO +'
                          }}
                        </small>
                      </div>
                    </div>
                    <v-divider
                      v-if="photographer.hasAnActiveSubscription && photographer.subscriptionType !== types.subscription.type.basic"
                      vertical
                      class="secondary d-none d-md-block mx-6"
                    />
                    <div class="pointer" @click="openUserDialog('following')">
                      <strong>{{ photographer.followingCount }}</strong>
                      Following
                    </div>
                    <v-divider vertical class="secondary mx-6" />
                    <div class="pointer" @click="openUserDialog('followers')">
                      <strong>{{ photographer.followedByCount }}</strong>
                      Followers
                    </div>
                  </div>
                  <div class="d-md-none">
                    <Buttons
                      :me="me"
                      :photographer="photographer"
                      :start-chat="startChat"
                      :is-following="isFollowing"
                      :loading-follow="loadingFollow"
                      :follow-user="followUser"
                      color="secondary"
                    />
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <v-container>
      <v-row>
        <v-col cols="12" class="py-0">
          <div class="d-md-flex justify-space-between align-center">
            <div>
              <template v-if="$vuetify.breakpoint.smAndDown">
                <v-tabs
                  v-model="activeTab"
                  :active-class="
                    $vuetify.theme.dark ? 'secondary--text' : 'primary--text'
                  "
                  :slider-color="$vuetify.theme.dark ? 'secondary' : 'primary'"
                  grow
                  background-color="transparent"
                  icons-and-text
                >
                  <v-tab v-for="(tab, i) in tabs" :key="i" :href="`#tab-${i}`">
                    <v-icon>
                      {{ tab.icon }}
                    </v-icon>
                  </v-tab>
                </v-tabs>
              </template>
              <template v-else>
                <v-tabs
                  v-model="activeTab"
                  :active-class="
                    $vuetify.theme.dark ? 'secondary--text' : 'primary--text'
                  "
                  :slider-color="$vuetify.theme.dark ? 'secondary' : 'primary'"
                  background-color="transparent"
                  icons-and-text
                >
                  <v-tab v-for="(tab, i) in tabs" :key="i" :href="`#tab-${i}`">
                    <span>
                      {{ tab.label }}
                    </span>
                    <v-icon small class="mb-2">
                      {{ tab.icon }}
                    </v-icon>
                  </v-tab>
                </v-tabs>
              </template>
            </div>
            <div class="d-none d-md-block">
              <Buttons
                :me="me"
                :photographer="photographer"
                :start-chat="startChat"
                :is-following="isFollowing"
                :loading-follow="loadingFollow"
                :follow-user="followUser"
                color="primary"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-divider />
    <v-tabs-items v-model="activeTab" class="pb-12">
      <v-tab-item value="tab-0">
        <div class="secondary pt-3">
          <Photos
            :me="me"
            :mounting="mounting"
            :loading="loading"
            :photographer="photographer"
            :photos="photos"
            :load-more="loadMore"
            :auto-load-disabled="autoLoadDisabled"
            :on-filter-change="onFilterChange"
          />
        </div>
      </v-tab-item>
      <v-tab-item value="tab-1">
        <div class="secondary pt-3">
          <Albums
            :me="me"
            :mounting="mounting"
            :loading="loading"
            :photographer="photographer"
            :albums="albums"
            :auto-load-disabled="albumsAutoLoadDisabled"
            :load-more="loadMoreAlbums"
          />
        </div>
      </v-tab-item>
      <v-tab-item value="tab-2">
        <div class="secondary pt-3">
          <Galleries
            :me="me"
            :mounting="mounting"
            :loading="loading"
            :photographer="photographer"
            :galleries="galleries"
            :auto-load-disabled="galleriesAutoLoadDisabled"
            :load-more="loadMoreGalleries"
          />
        </div>
      </v-tab-item>
      <v-tab-item value="tab-3">
        <div class="secondary pt-3">
          <Info :me="me" :photographer="photographer" />
        </div>
      </v-tab-item>
      <v-tab-item value="tab-4">
        <div class="secondary pt-3">
          <Stats
            :me="me"
            :photographer="photographer"
            :reputation="reputation"
            :reputation-view="reputationView"
            :reputation-update-view="reputationUpdateView"
            :loading-reputation-period="loadingReputationPeriod"
            :activity="activity"
            :activity-view="activityView"
            :activity-update-view="activityUpdateView"
            :loading-activity-period="loadingActivityPeriod"
            :load-last-reputation="loadLastReputation"
            :loading-latest-reputation="loadingLatestReputation"
            :last-reputation-loaded="lastReputationLoaded"
            :load-last-activities="loadLastActivities"
            :loading-latest-user-activities="loadingLatestUserActivities"
            :last-activities-loaded="lastActivitiesLoaded"
            :overview="photographerOverview"
            :loading-overview="loadingOverview"
          />
        </div>
      </v-tab-item>
      <v-tab-item value="tab-5">
        <div class="secondary pt-3">
          <Achievements
            :photographer="photographer"
            :achievements="achievements"
          />
        </div>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog v-model="userDialog" width="500">
      <UsersDialog
        :me="me"
        :data="followData"
        :load-more="userDialogLoadMore"
        :loading="loading"
        :is-last-page="isLastPage"
        :follow-user="followUser"
        :loading-follow="loadingFollow"
      />
    </v-dialog>
  </div>
</template>

<script>

import moment from 'moment'
import { mapState } from 'vuex'
import Photos from '@/components/photographer/Photos.vue'
import Buttons from '@/components/photographer/Buttons.vue'
import types from '@/utils/types'
import { EventBus } from '@/plugins/event-bus'

export default {
  filters: {
    printdate: date => {
      return moment.utc(date).local().format('D MMMM YYYY')
    }
  },
  components: {
    Photos,
    Buttons,
    Achievements: () => import('@/components/photographer/Achievements.vue'),
    Stats: () => import('@/components/photographer/Stats.vue'),
    Albums: () => import('@/components/photographer/Albums.vue'),
    Galleries: () => import('@/components/photographer/Galleries.vue'),
    Info: () => import('@/components/photographer/Info.vue'),
    UsersDialog: () => import('@/components/common/UsersDialog.vue')
  },
  middleware: 'header',
  asyncData({ query }) {
    const t = query.t || '0'
    const activeTab = `tab-${t}`
    return {
      activeTab
    }
  },
  data() {
    return {
      types,
      mounting: false,
      mounted: false,
      loadingFollow: false,
      loading: false,
      userDialog: false,
      nude: false,
      loadingLatestReputation: false,
      loadingLatestUserActivities: false,
      tabs: [
        { label: 'Photos', icon: 'fal fa-images' },
        { label: 'Albums', icon: 'fal fa-books' },
        { label: 'Galleries', icon: 'fal fa-layer-group' },
        { label: 'Info', icon: 'fal fa-book-user' },
        { label: 'Stats', icon: 'fal fa-user-chart' },
        { label: 'Achievements', icon: 'fal fa-trophy-alt' }
      ],
      activeTab: 'tab-0',
      analyticsLoaded: false,
      loadingOverview: false
    }
  },
  async fetch({ params, store, $asa }) {
    try {
      const id = params.id
      await store.dispatch('users/getUser', id)
    } catch (error) {
      $asa.appInsights('Pages: photographer/_id.vue - method: fetch', error)
    }
  },
  head({ $seo }) {
    return $seo({
      title: this.fullName,
      description: `See ${this.fullName}'s photos on 100ASA. Best images of their portfolio. Learn from the best.`,
      url: `https://100asa.com/photographer/${
        this.photographer.userName || this.photographer.id
      }`,
      image: this.photographer.bestUserPhoto || false
    })
  },
  computed: {
    ...mapState({
      me: state => state.auth.user,
      photographer: state => state.users.photographer,
      photographerOverview: state => state.users.overview,
      photos: state => state.users.photos,
      photosIsLastPage: state => state.users.isLastPage,
      photosPage: state => state.users.page,
      reputation: state => state.users.reputation,
      reputationView: state => state.users.reputationView,
      loadingReputationPeriod: state => state.users.loadingReputationPeriod,
      activity: state => state.users.activity,
      activityView: state => state.users.activityView,
      loadingActivityPeriod: state => state.users.loadingActivityPeriod,
      isFollowing: state => state.following.isFollowing,
      followData: state => state.following.data,
      isLastPage: state => state.following.isLastPage,
      page: state => state.following.page,
      lastReputationLoaded: state => state.users.lastReputationLoaded,
      lastActivitiesLoaded: state => state.users.lastActivitiesLoaded,
      galleries: state => state.galleries.userGalleries,
      galleriesPage: state => state.galleries.userGalleriesPage,
      galleriesIsLastPage: state => state.galleries.userGalleriesIsLastPage,
      albums: state => state.albums.userAlbums,
      albumsPage: state => state.albums.userAlbumsPage,
      albumsIsLastPage: state => state.albums.userAlbumsIsLastPage,
      achievements: state => state.users.achievements
    }),
    fullName() {
      return `${this.photographer.name} ${this.photographer.lastname}`
    },
    autoLoadDisabled() {
      return (
        this.activeTab === 'tab-0' &&
        !this.loading &&
        !this.mounting &&
        this.photos.length > 0 &&
        !this.photosIsLastPage
      )
    },
    albumsAutoLoadDisabled() {
      return (
        this.activeTab === 'tab-1' &&
        !this.loading &&
        !this.mounting &&
        this.albums.length > 0 &&
        !this.albumsIsLastPage
      )
    },
    galleriesAutoLoadDisabled() {
      return (
        this.activeTab === 'tab-2' &&
        !this.loading &&
        !this.mounting &&
        this.galleries.length > 0 &&
        !this.galleriesIsLastPage
      )
    }
  },
  async mounted() {
    this.mounted = true
    this.$store.dispatch('account/setCurationDialog', {
      visible: false
    })
    const id = this.photographer.id
    if (this.me) {
      if (this.me.id === this.photographer.id) {
        this.nude = true
      } else {
        this.nude = !this.me.isNudeFilterActive
      }
    }
    const data = {
      user: id,
      page: 1,
      nude: this.nude,
      sort: 'desc',
      showUploaded: this.photographer.role === types.user.role.ambassador
    }
    const albumAndGalleriesData = {
      userId: id,
      page: 1
    }
    try {
      this.mounting = true
      this.$store.commit('albums/RESET_USER_ALBUMS')
      await this.$store.dispatch('following/resetStore')
      if (this.me && this.me.id) {
        await this.$store.dispatch('users/isUserBlocked', id)
        await this.$store.dispatch('following/isUserFollowing', {
          id,
          from: 'photographer'
        })
      }
      await this.$store.dispatch('albums/resetManageAlbum')
      await this.$store.dispatch('galleries/resetSelectedGallery')
      await this.$store.dispatch('users/getUserPhotos', data)
      await this.$store.dispatch('albums/getUserAlbums', albumAndGalleriesData)
      await this.$store.dispatch(
        'galleries/getUserGalleries',
        albumAndGalleriesData
      )
      if (this.me && this.me.hasAnActiveSubscription) {
        this.loadingOverview = true
        await this.$store.dispatch('users/getUserOverview', id)
        await this.$store.dispatch('users/getReputation', {
          id,
          period: types.period.weekly
        })
        await this.$store.dispatch('users/getActivity', {
          id,
          period: types.period.weekly
        })
      }
      await this.$store.dispatch('users/getUserAchievements', id)
    } catch (error) {
      this.$asa.appInsights('Pages: photographer/_id.vue - method: mounted', error)
    } finally {
      this.loadingOverview = false
      this.mounting = false
    }
  },
  methods: {
    async loadLastReputation() {
      try {
        this.loadingLatestReputation = true
        await this.$store.dispatch('users/getLatestReputation', {
          id: this.photographer.id,
          page: 1
        })
      } catch (error) {
        this.$asa.appInsights('Pages: photographer/_id.vue - method: loadLastReputation', error)
      } finally {
        this.loadingLatestReputation = false
      }
    },
    async loadLastActivities() {
      try {
        this.loadingLatestUserActivities = true
        await this.$store.dispatch('users/getLatestUserActions', {
          id: this.photographer.id,
          page: 1
        })
      } catch (error) {
        this.$asa.appInsights('Pages: photographer/_id.vue - method: loadLastActivities', error)
      } finally {
        this.loadingLatestUserActivities = false
      }
    },
    async reputationUpdateView(period) {
      try {
        await this.$store.dispatch('users/getReputation', {
          id: this.photographer.id,
          period
        })
      } catch (error) {
        this.$asa.appInsights('Pages: photographer/_id.vue - method: reputationUpdateView', error)
      }
    },
    async activityUpdateView(period) {
      try {
        await this.$store.dispatch('users/getActivity', {
          id: this.photographer.id,
          period
        })
      } catch (error) {
        this.$asa.appInsights('Pages: photographer/_id.vue - method: activityUpdateView', error)
      }
    },
    async followUser(id, following, from) {
      if (this.me) {
        try {
          this.loadingFollow = true
          await this.$store.dispatch(
            `following/${following ? 'unfollow' : 'follow'}`,
            {
              userId: id
            }
          )
          await this.$store.dispatch('feeds/resetStore')
          await this.$store.dispatch('following/isUserFollowing', {
            id,
            from
          })
          await this.$auth.fetchUser()
        } catch (error) {
          this.$asa.appInsights('Pages: photographer/_id.vue - method: followUser', error)
        } finally {
          this.loadingFollow = false
        }
      } else {
        EventBus.$emit('snackbar', {
          text: 'You need to log in to follow other photographers',
          color: 'info'
        })
      }
    },
    async openUserDialog(type) {
      try {
        this.loading = true
        await this.$store.dispatch('following/resetData')
        await this.$store.dispatch('following/setType', type)
        this.userDialog = true
        await this.$store.dispatch('following/getData', {
          id: this.photographer.id,
          page: 1
        })
      } catch (error) {
        this.$asa.appInsights('Pages: photographer/_id.vue - method: openUserDialog', error)
      } finally {
        this.loading = false
      }
    },
    async userDialogLoadMore() {
      if (!this.isLastPage && !this.loading) {
        this.loading = true
        try {
          await this.$store.dispatch('following/getData', {
            id: this.photographer.id,
            page: this.page + 1
          })
        } catch (error) {
          this.$asa.appInsights('Pages: photographer/_id.vue - method: userDialogLoadMore', error)
        } finally {
          this.loading = false
        }
      }
    },
    async onFilterChange(index) {
      const status = ['elite', 'inspiration', 'prime', 'upcoming', 'opening']
      const selectedStatus = status[index]
      await this.$store.dispatch('users/resetPhotos')
      await this.$store.dispatch('users/setSelectedStatus', selectedStatus)
      this.loading = true
      try {
        const data = {
          user: this.photographer.id,
          page: 1,
          nude: this.nude,
          status: selectedStatus,
          sort: 'desc'
        }
        await this.$store.dispatch('users/getUserPhotos', data)
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
    async loadMore() {
      if (
        !this.photosIsLastPage &&
        this.photos.length !== 0 &&
        (!this.loading || !this.mounting)
      ) {
        this.loading = true
        try {
          const data = {
            user: this.photographer.id,
            page: this.photosPage + 1,
            nude: this.nude,
            sort: 'desc'
          }
          await this.$store.dispatch('users/getUserPhotos', data)
        } catch (error) {
          this.$asa.appInsights('Pages: photographer/_id.vue - method: loadMore', error)
        } finally {
          this.loading = false
        }
      }
    },
    async loadMoreGalleries() {
      if (!this.galleriesIsLastPage && (!this.loading || !this.mounting)) {
        try {
          this.loading = true
          const data = {
            userId: this.photographer.id,
            page: this.galleriesPage + 1
          }
          await this.$store.dispatch('galleries/getUserGalleries', data)
        } catch (error) {
          this.$asa.appInsights('Pages: photographer/_id.vue - method: loadMoreGalleries', error)
        } finally {
          this.loading = false
        }
      }
    },
    async loadMoreAlbums() {
      if (!this.albumsIsLastPage && (!this.loading || !this.mounting)) {
        try {
          this.loading = true
          const data = {
            userId: this.photographer.id,
            page: this.albumsPage + 1
          }
          await this.$store.dispatch('albums/getUserAlbums', data)
        } catch (error) {
          this.$asa.appInsights('Pages: photographer/_id.vue - method: loadMoreAlbums', error)
        } finally {
          this.loading = false
        }
      }
    },
    startChat(photographer) {
      const data = {
        receiver: photographer
      }
      EventBus.$emit('chat', data)
    }
  }
}
</script>

<style scoped>

.photographer-hero {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 85vh;
  position: relative;
}
.overlay {
  background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
  width: 100%;
  height: 100%;
}
.asa-icon {
  vertical-align: text-top;
}
.w-80 {
  width: 80px;
}
.pro-badge {
  border-radius: 4px;
  display: block;
  padding: 0 12px;
}
</style>
