<template>
  <client-only>
    <div>
      <v-menu :close-on-content-click="false" offset-y>
        <template #activator="{ on, attrs }">
          <v-btn
            :small="isShortProfile"
            color="primary"
            dark
            outlined
            v-bind="attrs"
            v-on="on"
          >
            <v-icon small class="mr-3">fal fa-ellipsis-v</v-icon>
            Actions
          </v-btn>
        </template>
        <v-list :dense="isShortProfile">
          <v-list-item
            v-if="me && me.id === photographer.id"
            to="/account/profile"
          >
            <v-list-item-content>
              <v-list-item-title>
                <v-icon small class="w-30 mr-3"> fal fa-edit </v-icon>
                Edit
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="isShortProfile"
            nuxt
            :to="`/photographer/${photographer.userName || photographer.id}`"
          >
            <v-list-item-content>
              <v-list-item-title>
                <v-icon small class="w-30 mr-3"> fal fa-user </v-icon>
                Full Profile
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="
              me &&
              me.id !== photographer.id &&
              photographer.role !== types.user.role.curator
            "
            :disabled="loadingCheckChat"
            @click="checkAndSTartChat"
          >
            <v-list-item-content>
              <v-list-item-title>
                <v-icon v-if="loadingCheckChat" small class="w-30 mr-3"> fal fa-spin fa-spinner </v-icon>
                <v-icon v-else small class="w-30 mr-3"> fal fa-comment-lines </v-icon>
                Message
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="!me || (me && me.id !== photographer.id)"
            :disabled="loadingFollow"
            @click="followUser(photographer.id, isFollowing, 'photographer')"
          >
            <v-list-item-content>
              <v-list-item-title>
                <v-icon v-if="loadingFollow" small class="w-30 mr-3"> fal fa-spin fa-spinner </v-icon>
                <v-icon v-else small class="w-30 mr-3"> fal fa-{{ isFollowing ? 'user-minus' : 'user-plus' }} </v-icon>
                {{ isFollowing ? 'unfollow' : 'follow' }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="me && me.id !== photographer.id && photographer.role !== types.user.role.curator && photographer.role !== types.user.role.ambassador"
            :disabled="loadingBlock"
            @click="blockUser(photographer.id, photographer.isThisUserBlocked)"
          >
            <v-list-item-content>
              <v-list-item-title>
                <v-icon v-if="loadingBlock" small class="w-30 mr-3"> fal fa-spin fa-spinner </v-icon>
                <v-icon v-else small class="w-30 mr-3"> fal fa-ban </v-icon>
                {{ photographer.isThisUserBlocked ? 'unblock' : 'block' }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="shareDialog = true">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon small class="w-30 mr-3"> fal fa-share </v-icon>
                share
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-dialog v-model="shareDialog" width="326">
        <v-card outlined>
          <v-card-text class="pt-4">
            <client-only>
              <vue-goodshare-facebook
                :page_url="
                  `https://100asa.com/photographer/${photographer.userName ||
                    photographer.id}/`
                "
                has_icon
              />
              <vue-goodshare-twitter
                :page_url="
                  `https://100asa.com/photographer/${photographer.userName ||
                    photographer.id}/`
                "
                has_icon
              />
              <vue-goodshare-vkontakte
                :page_url="
                  `https://100asa.com/photographer/${photographer.userName ||
                    photographer.id}/`
                "
                has_icon
              />
              <vue-goodshare-linkedin
                :page_url="
                  `https://100asa.com/photographer/${photographer.userName ||
                    photographer.id}/`
                "
                has_icon
              />
              <vue-goodshare-pinterest
                :page_url="
                  `https://100asa.com/photographer/${photographer.userName ||
                    photographer.id}/`
                "
                has_icon
              />
              <vue-goodshare-reddit
                :page_url="
                  `https://100asa.com/photographer/${photographer.userName ||
                    photographer.id}/`
                "
                has_icon
              />
            </client-only>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </client-only>
</template>

<script>
import { displayConfirm } from '@/utils/swal'
import types from '@/utils/types'
import { EventBus } from '@/plugins/event-bus'

export default {
  name: 'PhotographerButtons',
  props: {
    me: {
      type: [Object, Boolean],
      default: () => {}
    },
    photographer: {
      type: Object,
      required: true
    },
    startChat: {
      type: Function,
      required: true
    },
    isFollowing: {
      type: Boolean,
      default: false
    },
    loadingFollow: {
      type: Boolean,
      default: false
    },
    followUser: {
      type: Function,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    isShortProfile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      types,
      loadingCheckChat: false,
      loadingBlock: false,
      shareDialog: false
    }
  },
  methods: {
    async checkAndSTartChat() {
      try {
        this.loadingCheckChat = true
        const canMessage = await this.$axios.$get(`/users/can-message?id=${this.photographer.id}`)
        if (canMessage) {
          this.startChat(this.photographer)
        } else {
          EventBus.$emit('snackbar', {
            text: 'You cannot message this user',
            color: 'info'
          })
        }
      } catch (error) {
        this.$asa.appInsights('Comp: photographer/Buttons.vue - method: checkAndSTartChat', error)
      } finally {
        this.loadingCheckChat = false
      }
    },
    getFollowingColor() {
      let color = 'primary'
      if (this.$vuetify.breakpoint.xsOnly && !this.isShortProfile) {
        color = this.$vuetify.theme.dark ? 'primary' : 'secondary'
      } else {
        color = this.isFollowing
          ? this.$vuetify.theme.dark
            ? 'secondary'
            : 'primary'
          : ''
      }
      return color
    },
    getBlockedColor() {
      let color = 'primary'
      if (this.$vuetify.breakpoint.xsOnly && !this.isShortProfile) {
        color = this.$vuetify.theme.dark ? 'primary' : 'secondary'
      } else {
        color = this.photographer.isThisUserBlocked
          ? this.$vuetify.theme.dark
            ? 'secondary'
            : 'primary'
          : ''
      }
      return color
    },
    async blockUser(id, isBlocked) {
      try {
        this.loadingBlock = true
        if (isBlocked) {
          await this.$store.dispatch('users/unblock', id)
          EventBus.$emit('snackbar', {
            text: 'The User has been successfully unblocked',
            color: 'success'
          })
        } else {
          displayConfirm(
            '<div><h3 class="text-title font-weight-bold mb-3">You are about to block this user</h3><div class="text-left"><p class="text-body-2">Once blocked they will no longer be able to:</p><ul class="mb-3"><li>Curate your photos</li><li>Comment your photos</li><li>Message You</li></ul><p class="text-caption mb-0">Any previous chat messages with this user will be deleted.</p></div></div>',
            async result => {
              if (result.isConfirmed) {
                await this.$store.dispatch('users/block', id)
                const chat = await this.$axios.$post(
                  `${process.env.baseUrl}/proxy/talkjs-get-coversation-by-user-id`,
                  { me: this.me.id, other: id }
                )
                if (chat && chat.id) {
                  await this.$axios.$post(
                    `${process.env.baseUrl}/proxy/talkjs-delete-coversation-by-id`,
                    { id: chat.id }
                  )
                }
                EventBus.$emit('snackbar', {
                  text: 'The User has been successfully blocked',
                  color: 'success'
                })
              }
            }
          )
        }
      } catch (error) {
        this.$asa.appInsights('Comp: photographer/Buttons.vue - method: blockUser', error)
      } finally {
        this.loadingBlock = false
      }
    }
  }
}
</script>
