<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="px-0">
        <div class="text-right mr-3 mb-3">
          <span class="mr-3">Filter by:</span>
          <v-btn
            v-if="photoFilter !== undefined"
            icon
            outlined
            small
            class="mr-3"
            @click="resetFilters"
          >
            <v-icon small>fal fa-times</v-icon>
          </v-btn>
          <v-btn-toggle v-model="photoFilter" @change="onFilterChange">
            <v-btn small>
              Elite
            </v-btn>
            <v-btn small>
              Inspiration
            </v-btn>
            <v-btn small>
              Prime
            </v-btn>
            <v-btn small>
              Upcoming
            </v-btn>
            <v-btn small>
              Opening
            </v-btn>
          </v-btn-toggle>
        </div>
        <vue-justified-layout :items="photos">
          <template slot="inner" slot-scope="slotProps">
            <nuxt-link
              v-if="slotProps && slotProps.item"
              :to="
                `/photo/${slotProps.item.id}/${slotProps.item.slug}?from=photographer&pid=${pid}`
              "
              class="photo-link"
            >
              <v-hover>
                <v-img
                  :key="slotProps.item.id"
                  slot-scope="{ hover }"
                  :src="$asa.getImageUrl(slotProps.item, 'thumb')"
                  :lazy-src="$asa.getImageUrl(slotProps.item, 'thumb', true)"
                  :alt="
                    `${slotProps.item.title} by ${slotProps.item.photographerName} - 100ASA`
                  "
                >
                  <template #placeholder>
                    <v-row
                      class="fill-height ma-0 color"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                  <div
                    v-if="
                      ((me && me.isNudeFilterActive) || !me) &&
                        slotProps.item.isNude
                    "
                    class="obscure-photo"
                  >
                    <p class="text-body-2 ma-0">NSFW Content</p>
                  </div>
                  <div
                    v-if="
                      slotProps.item.statusName !==
                        types.photo.status.inCuration &&
                        slotProps.item.statusName !==
                          types.photo.status.uploaded
                    "
                    :class="{
                      'lime darken-2':
                        slotProps.item.statusName ===
                        types.photo.status.inspiration,
                      success:
                        slotProps.item.statusName === types.photo.status.prime,
                      info:
                        slotProps.item.statusName ===
                        types.photo.status.upcoming,
                      purple:
                        slotProps.item.statusName ===
                        types.photo.status.opening,
                      gold:
                        slotProps.item.statusName === types.photo.status.elite
                    }"
                    class="corner-ribbon white--text"
                  >
                    {{
                      $asa.getFriendlyPhotoStatusName(slotProps.item.statusName)
                    }}
                  </div>
                  <v-fade-transition mode="in-out">
                    <div
                      v-if="hover"
                      class="d-flex transition-fast-in-fast-out photo-overlay pa-2"
                      style="height: 50px"
                    >
                      <div
                        class="d-flex pl-1 credits justify-end align-center w-100-percentage"
                      >
                        <div>
                          <v-btn
                            color="secondary"
                            icon
                            @click="
                              openAddToGalleryDialog($event, slotProps.item)
                            "
                          >
                            <v-icon small>
                              fal fa-layer-plus
                            </v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </v-fade-transition>
                </v-img>
              </v-hover>
            </nuxt-link>
          </template>
        </vue-justified-layout>
        <div v-intersect="onIntersect" />
        <div v-if="loading" class="text-center">
          <v-icon class="fa-spin my-3"> fal fa-spinner </v-icon>
        </div>
        <div v-if="!loading && !mounting && photos.length === 0">
          <div class="text-center pt-2">
            <h3 class="text-h6 mb-2">No photos</h3>
            <p class="text-subtitle-1">
              There are not photos to show here
            </p>
          </div>
        </div>
      </v-col>
    </v-row>
    <Galleries-dialog />
  </v-container>
</template>

<script>
import VueJustifiedLayout from 'vue-justified-layout'
import types from '@/utils/types'

export default {
  name: 'PhotographerPhotos',
  components: {
    VueJustifiedLayout,
    GalleriesDialog: () => import('@/components/galleries/GalleriesDialog.vue')
  },
  props: {
    me: {
      type: [Object, Boolean],
      default: () => {}
    },
    mounting: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    photographer: {
      type: Object,
      default: () => {}
    },
    photos: {
      type: Array,
      default: () => []
    },
    loadMore: {
      type: Function,
      default: () => {}
    },
    autoLoadDisabled: {
      type: Boolean,
      default: false
    },
    onFilterChange: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      types,
      pid: this.photographer.id,
      photoFilter: undefined
    }
  },
  methods: {
    openAddToGalleryDialog(event, photo) {
      event.preventDefault()
      this.$store.dispatch('galleries/setSelectedPhotoToAdd', photo)
      this.$store.dispatch('galleries/setAddPhotoToGalleryDialogVisible', true)
    },
    onIntersect(entries) {
      if (entries[0].intersectionRatio > 0 && this.autoLoadDisabled) {
        this.loadMore()
      }
    },
    resetFilters() {
      this.onFilterChange(undefined)
      this.photoFilter = undefined
    }
  }
}
</script>

<style scoped>
.photo-link {
  text-decoration: none;
}
.corner-ribbon {
  font-size: 10px;
  line-height: 15px;
  position: absolute;
  top: 11px;
  left: auto;
  right: -25px;
  text-align: center;
  text-transform: lowercase;
  transform: rotate(45deg);
  width: 90px;
}
.obscure-photo {
  align-items: center;
  background-color: #212121;
  color: white;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.photo-overlay {
  align-items: center;
  background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>
